import React from "react";
import { CmsContent, Container, Layout, Link, SocialsSharing } from "components";
import { FeaturedPosts, Hero, Loader } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { ContainerMarginEnum, ContainerWidthEnum } from "utils/types/container";
import { Post_By_IdQuery } from "codegen";
import "./single-post.scss";
import { graphql } from "gatsby";

interface BlogPostTemplate {
  data: Post_By_IdQuery;
}

const BlogPostTemplate = ({ data: { post, wp } }: BlogPostTemplate): JSX.Element => {
  const { title, seo, acfLayout, acfLayoutPosts } = post;
  const {
    webAppSettings: {
      webAppSettings: { postSharingOptions },
    },
  } = wp;

  return (
    <Layout layoutType="post" pageTitle={title} seo={seo} className="single-post" article={post}>
      <>
        <Hero
          data={{
            content: `<p class=\"eyebrow\">Resources</p>\n<h1>${post.title}</h1><p className="single-post__info__date-author">${post.date}<br />${post?.author?.node?.name}</p>\n`,
            image: post?.featuredImage?.node,
          }}
          location={seo?.breadcrumbs}
        />
        
        {acfLayout?.layout?.length > 0 ? (
          <Loader
            data={acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
            }))}
            location={seo?.breadcrumbs}
          />
        ) : (
          post.content && (
            <Container
              containerWidth={ContainerWidthEnum.LAYOUT_SMALL}
              sectionSpacing={ContainerMarginEnum.SECTION_NONE}
            >
              {/* Using CmsContent here incase we're using shortcodes */}
              <CmsContent content={post.content} />
            </Container>
          )
        )}
        {postSharingOptions && <SocialsSharing socialItems={postSharingOptions} />}
        <FeaturedPosts
          data={{
            feedContent: `<h2>Our latest blog posts</h2><a class="button--primary" href="/blog/">View all posts</a>`,
          }}
        />
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "posts" })(BlogPostTemplate);

export const PostByIdQuery = graphql`
  query POST_BY_ID($id: String!, $previousPostId: String, $nextPostId: String) {
    post: wpPost(id: { eq: $id }) {
      ...PostFields
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
          ...HomepageHeroQuery
          ...ContactFormBlockQuery
          ...FeaturedPostsQuery
          ...TeamProfilesQuery
          ...FlexibleBlockQuery
          ...QuickLinksQuery
          ...LinkableCardsQuery
          ...ImageAndContentBlockQuery
          ...CardSliderQuery
          ...CtaBannerQuery
          ...ImageAndContentSliderBlockQuery
          ...TwoColumnWithCalloutQuery
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    wp {
      webAppSettings {
        webAppSettings {
          postSharingOptions
        }
      }
    }
  }
`;
